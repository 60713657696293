const priceChar = {
    'AUD': '$',
	'CAD': '$',
	'CHF': 'CHF',
	'CNY': '¥',
	'DKK': 'kr',
	'EUR': '€',
	'GBP': '£',
	'HKD': '$',
	'JPY': '¥',
	'MYR': 'RM',
	'SGD': '$',
	'THB': '฿',
	'USD': '$',
}

export default priceChar