import * as apis from '../../redux/apis'

import { Platform, Text, TouchableOpacity, View, Animated } from 'react-native'

import React from 'react'
import _ from 'lodash'
import actions from '../../redux/actions'
import { connect } from 'react-redux'
import { filePicker } from 'react-native-file-picker'
import generateMessage from '../../utils/generateMessage'
import { setVariables } from '../../modules/setVariables'
import { animeInterval } from '../../constants'

class VerifyTransaction extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			pregress: 0,
			fadeAnim: new Animated.Value(0),
		}
	}

	componentDidMount = () => {
		this.inAnime()
		/* const { getUser} = this.props
		getUser() */
	}

	inAnime = () => {
		Animated.timing(
			this.state.fadeAnim,
			{
				toValue: styles.button.height,
				duration: animeInterval,
			}
		).start()
	}

	outAnime = () => {
		Animated.timing(
			this.state.fadeAnim,
			{
				toValue: 0,
				duration: animeInterval,
			}
		).start()
	}

	onSend = () => {
		const { question, answers, storeAnswers, onSend, variables, addMessageToChatSaga, user, app } = this.props

		filePicker().then(({ uri, name }) => {
			if (!uri) return

			const uploadData =
				Platform.OS === 'web'
					? {
						data: uri,
						func: 'uploadBlob',
					}
					: {
						data: uri,
						func: 'uploadFile',
					}

			this.setState({
				isUploading: true,
			})

			let extension = ''
			try {
				const arr = name.split('.')
				extension = arr[arr.length - 1]
			} catch (e) {

			}

			apis[uploadData.func](uploadData.data, this.progressCallback, extension, this.props.app._id).then(({ link }) => {
				this.setState({
					isConfirmation: true,
				})

				onSend(
					{
						image: link,
					}
				)

				let userId = user

				if (user === "" || user === undefined) {
					userId = app._id
				}

				apis.checkVerifyTransaction({
					transaction_image_url: link,
					bank: question.input.bank,
				}, userId).then(
					async (data) => {

						if (data.statusCode !== "1000") {
							addMessageToChatSaga(
								{
									text: `${question.input.error_message} <${data.statusMessage}>`,
									question,
									trigger: _.get(question, 'id')
								}
							)
							return
						}

						if (data.isUsed) {
							addMessageToChatSaga(
								{
									text: `The slip transaction has been used`,
									question,
									trigger: _.get(question, 'id')
								}
							)
						} else {
							const currenctQrCodePayment = await apis.getCurrentQrCodePayment(userId)

							let last4CharactersProxyValue = ""
							if (data.data.receiver.proxy.value.length >= 4) {
								last4CharactersProxyValue = data.data.receiver.proxy.value.slice(-4);
							}

							let isMatchAccount = true
							let isMatchBank = true
							let last4CharactersAccountValue = ""
							if (question.input.bank !== data.createByBank) {
								isMatchAccount = false
							}

							switch (question.input.bank) {
								case "KBANK":
									if (data.createByBank !== "KBANK") {
										isMatchBank = false
									}

									switch (currenctQrCodePayment.payment_type) {
										case "mobile_number":
											if (data.data.receiver.proxy.type !== "MSISDN") {
												isMatchAccount = false
											}
											if (currenctQrCodePayment.mobile_number.length >= 4) {
												last4CharactersAccountValue = currenctQrCodePayment.mobile_number.slice(-4)
											}
											break;
										case "national_id_or_tax_id":
											if (data.data.receiver.proxy.type !== "NATID") {
												isMatchAccount = false
											}
											if (currenctQrCodePayment.national_id_or_tax_id.length >= 4) {
												last4CharactersAccountValue = currenctQrCodePayment.national_id_or_tax_id.slice(-4)
											}
											break;
										case "e_wallet_id":
											if (data.data.receiver.proxy.type !== "EWALLETID") {
												isMatchAccount = false
											}
											if (currenctQrCodePayment.e_wallet_id.length >= 4) {
												last4CharactersAccountValue = currenctQrCodePayment.e_wallet_id.slice(-4)
											}
											break;
										default:
											break;
									}
									break;
								case "SCB":
									if (data.createByBank !== "SCB") {
										isMatchBank = false
									}

									switch (currenctQrCodePayment.payment_type) {
										case "mobile_number":
											if (data.data.receiver.proxy.type !== "MSISDN") {
												isMatchAccount = false
											}
											if (currenctQrCodePayment.mobile_number.length >= 4) {
												last4CharactersAccountValue = currenctQrCodePayment.mobile_number.slice(-4)
											}
											break;
										case "national_id_or_tax_id":
											if (data.data.receiver.proxy.type !== "NATID") {
												isMatchAccount = false
											}
											if (currenctQrCodePayment.national_id_or_tax_id.length >= 4) {
												last4CharactersAccountValue = currenctQrCodePayment.national_id_or_tax_id.slice(-4)
											}
											break;
										case "e_wallet_id":
											if (data.data.receiver.proxy.type !== "EWALLETID") {
												isMatchAccount = false
											}
											if (currenctQrCodePayment.e_wallet_id.length >= 4) {
												last4CharactersAccountValue = currenctQrCodePayment.e_wallet_id.slice(-4)
											}
											break;
										default:
											break;
									}
									break;
								default:
									break;
							}

							if (!isMatchBank) {
								addMessageToChatSaga(
									{
										text: "The bank gateway not match.",
										question,
										trigger: _.get(question, 'id')
									}
								)
								return
							}
							if (!isMatchAccount || !(last4CharactersProxyValue === last4CharactersAccountValue)) {
								addMessageToChatSaga(
									{
										text: "The receiver account not match.",
										question,
										trigger: _.get(question, 'id')
									}
								)
							} else {
								apis.updateVerifyConfirmation(userId, data.uuid)
								const answer = {
									[question.id]: {
										answer: {
											response: {
												value: data,
											},
										},
										mode: question.mode,
									},
								}

								this.setState({
									isUploading: false,
									progress: 0,
								})

								setVariables(question.id, variables, answer)

								// store answers in store
								storeAnswers(
									{
										...answers,
										...answer,
									},
									answer
								)

								// generate answer and replace expression
								const message = generateMessage(question.answer, answers, answer)

								addMessageToChatSaga(
									{
										text: message,
										question,
										trigger: _.get(question, 'trigger.right'),
									}
								)

								this.outAnime()
							}
						}
					},
					(data) => {
						addMessageToChatSaga(
							{
								text: `${question.input.error_message}`,
								question,
								trigger: _.get(question, 'id'),
							}
						)
					}
				)
			})
		})
	}

	progressCallback = (percent) => {
		this.setState({
			progress: percent * 100,
		})
	}

	render = () => {
		const { question } = this.props

		const text = _.get(question, 'input.button.text', 'UPLOAD TRANSACTION SLIP')
		const { progress, isUploading } = this.state
		return (
			<View>
				<Animated.View
					style={{
						height: this.state.fadeAnim,
					}}
				>
					{isUploading && (
						<View style={styles.progressContainer}>
							<View style={[styles.progressBar, { width: `${progress}%` }]}></View>
						</View>
					)}
					<TouchableOpacity
						disabled={isUploading}
						onPress={this.onSend}
						style={styles.button}
					>
						<Text numberOfLines={1} style={styles.text}>
							{text}
						</Text>
					</TouchableOpacity>
				</Animated.View>
			</View>
		)
	}
}

const mapStateToProps = (state) => ({
	currentQuestion: state.chat.currentQuestion,
	answers: state.chat.answers,
	currentAnswer: state.chat.currentAnswer,
	currentQrCodePayment: state.chat.currentQrCodePayment,
	isVerifyTransactionFailed: state.chat.isVerifyTransactionFailed,
	user: state.chat.user,
	app: state.chat.app,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
	storeAnswers: (answers, answer) => {
		dispatch(actions.answers(answers, answer))
	},
	addMessageToChatSaga: (message) => {
		dispatch(actions.addMessageToChat(message))
	},
})

const Container = connect(mapStateToProps, mapDispatchToProps)(VerifyTransaction)

export default Container

const styles = {
	button: {
		alignItems: 'center',
		backgroundColor: '#F8F8F8',
		borderColor: '#EEE',
		borderTopWidth: 1,
		borderWidth: 0.5,
		height: 60,
		justifyContent: 'center',
	},
	progressBar: {
		backgroundColor: '#007bff',
		borderRadius: 5,
		height: 5,
		transition: 'width .6s ease',
		width: '0%',
	},
	progressContainer: {
		margin: 1,
	},
	text: {
		color: '#4B4B4B',
		fontSize: 14,
	},
	errorBox: {
		alignItems: 'center',
		backgroundColor: '#FFAAAA',
		flexDirection: 'row',
		height: 40,
	},
	closeButton: {
		alignItems: 'center',
		justifyContent: 'center',
		paddingLeft: 10,
		paddingRight: 10,
	},
	textError: {
		color: 'white',
	},
}
