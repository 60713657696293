import "./index.scss";
import HybridApp from "./app";
import React from "react";
import ReactDOM from "react-dom/client";
import actions from "./redux/actions";
import reportWebVitals from "./reportWebVitals";
import { store } from "./redux/index";

// addEventListener support for IE8
function bindEvent(element, eventName, eventHandler) {
	if (element.addEventListener) {
		element.addEventListener(eventName, eventHandler, false);
	} else if (element.attachEvent) {
		element.attachEvent("on" + eventName, eventHandler);
	}
}

// Listen to messages from parent window
bindEvent(window, "message", function (e) {
	if (e.data && e.data.source === "DESIGN-CONFIG") {
		store.dispatch(actions.design(e.data.data));
	}
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	// <React.StrictMode>
	<div className="app-container">
		<HybridApp />
	</div>
	// </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
