import * as FilePicker from "react-native-file-picker";
import Alert from "react-native-alert";
import CustomActions from "../components/chatMobules/customActions";
import CustomBubble from "../components/chatMobules/customBubble";
import CustomSystem from "../components/chatMobules/customSystem";
import CustomView from "../components/chatMobules/customView";
import React, { useEffect, useState } from "react";
import actions from "../redux/actions";
import { Dimensions, View, useWindowDimensions } from "react-native";
import { GiftedChat } from "react-native-gifted-chat";
import { connect } from "react-redux";
import { footerMaxHeight } from "../constants";

const Content = (props) => {
	const {
		answers,
		credentials,
		currentAnswer,
		currentQuestion,
		failedMessages,
		isRetryingErrorMessage,
		retryErrorMessage,
		storeAnswers,
		storeCurrentAnswer,
		variables,
		editOrder,
		app,
		chatError,
		messages,
		onAddTimeout,
		onMessages,
		onSend,
		runMessage,
		design,
	} = props;

	const handleSend = (data, trigger) => {
		onSend(data, trigger);
	};

	const handleRunMessage = (id) => {
		runMessage(id);
	};

	const renderChatFooter = () => {
		return (
			<CustomActions
				app={app}
				onSend={handleSend}
				currentQuestion={currentQuestion}
				storeAnswers={storeAnswers}
				answers={answers}
				currentAnswer={currentAnswer}
				storeCurrentAnswer={storeCurrentAnswer}
				credentials={credentials}
				failedMessages={failedMessages}
				retryErrorMessage={retryErrorMessage}
				isRetryingErrorMessage={isRetryingErrorMessage}
				variables={variables}
				editOrder={editOrder}
				chatError={chatError}
			/>
		);
	};

	const renderInputToolbar = () => {
		return null;
	};

	const renderContainerFooter = () => {
		return (
			<View style={{ maxHeight: footerMaxHeight }}>{renderChatFooter()}</View>
		);
	};

	const renderBubble = (propsFunc) => {
		return (
			<CustomBubble
				{...propsFunc}
				design={design}
				onAddTimeout={onAddTimeout}
				onMessages={onMessages}
			/>
		);
	};

	const renderCustomView = (propsFunc) => {
		return <CustomView {...propsFunc} />;
	};

	const renderDay = () => {
		return <View />;
	};

	const renderSystemMessage = (propsFunc) => {
		return (
			<CustomSystem
				{...propsFunc}
				variables={variables}
				answers={answers}
				currentQuestion={currentQuestion}
				runMessage={handleRunMessage}
				currentAnswer={currentAnswer}
				storeCurrentAnswer={storeCurrentAnswer}
			/>
		);
	};

	// State to trigger re-render size GiftedChat
	let { width, height } = useWindowDimensions();
	height -= 60;

	const [windowSize, setWindowSize] = useState({ width, height });

	useEffect(() => {
		const updateWindowSize = () => {
			setWindowSize({ width, height });
		};

		const subscription = Dimensions.addEventListener(
			"change",
			updateWindowSize
		);

		return () => subscription.remove();
	}, [width, height]);

	return (
		<View
			behavior="padding"
			style={{
				flex: 1,
				width: windowSize.width,
				height: windowSize.height,
			}}
			className="chat-container"
		>
			<GiftedChat
				messages={Object.values(messages)}
				renderChatFooter={renderContainerFooter}
				renderInputToolbar={renderInputToolbar}
				minInputToolbarHeight={0}
				onSend={(message, trigger) => handleSend(message, trigger)}
				renderBubble={renderBubble}
				renderDay={renderDay}
				renderCustomView={renderCustomView}
				renderSystemMessage={renderSystemMessage}
				user={{
					_id: 1,
					id: 1,
				}}
			/>
			<Alert.view />
			<FilePicker.view />
		</View>
	);
};

const mapStateToProps = (state) => ({
	answers: state.chat.answers,
	app: state.chat.app,
	credentials: state.chat.credentials,
	currentAnswer: state.chat.currentAnswer,
	currentQuestion: state.chat.currentQuestion,
	design: state.chat.design,
	failedMessages: state.chat.failedMessages,
	isRetryingErrorMessage: state.chat.isRetryingErrorMessage,
	messages: state.chat.messages,
	variables: state.chat.variables,
	editOrder: state.chat.editOrder,
	chatError: state.chat.chatError,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
	onAddTimeout: (timeout) => {
		dispatch(actions.onAddTimeout(timeout));
	},
	onMessages: (messages) => {
		dispatch(actions.messages(messages));
	},
	onSend: (data, trigger) => {
		dispatch(actions.onSend(data, trigger));
	},
	retryErrorMessage: () => {
		dispatch(actions.retryErrorMessage());
	},
	runMessage: (id) => {
		dispatch(actions.runMessage(id));
	},
	storeAnswers: (answers, answer) => {
		dispatch(actions.answers(answers, answer));
	},
	storeCurrentAnswer: (id, answer) => {
		dispatch(actions.currentAnswer(id, answer));
	},
});

const Container = connect(mapStateToProps, mapDispatchToProps)(Content);

export default Container;
