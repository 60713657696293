const types = {
	ACCESS_TOKEN: 'ACCESS_TOKEN',
	ADD_MESSAGE_TO_CHAT: 'ADD_MESSAGE_TO_CHAT',
	ANSWERS: 'ANSWERS',
	APP: 'APP',
	APP_INTRO: 'APP_INTRO',
	ASK_CONFIRM_RESTART: 'ASK_CONFIRM_RESTART',
	CLEAE_STATE_WITHOUT_REFRESH: 'CLEAE_STATE_WITHOUT_REFRESH',
	CLEAR_STATE: 'CLEAR_STATE',
	CONFIG: 'CONFIG',
	CREDENTIALS: 'CREDENTIALS',
	CURRENT_ANSWER: 'CURRENT_ANSWER',
	CURRENT_QUESTION: 'CURRENT_QUESTION',
	DESIGN: 'DESIGN',
	DONE: 'DONE',
	FAILED_MESSAGES: 'FAILED_MESSAGES',
	FETCH_REQUIRE_DATA: 'FETCH_REQUIRE_DATA',
	IS_LOADING_REQUIRE_DATA: 'IS_LOADING_REQUIRE_DATA',
	IS_REQUESTING: 'IS_REQUESTING',
	IS_RESTARTING: 'IS_RESTARTING',
	IS_SHOWING_INTRO: 'IS_SHOWING_INTRO',
	LAST_QUESTION_ANSWER: 'LAST_QUESTION_ANSWER',
	MESSAGES: 'MESSAGES',
	MESSAGES_QUEUE: 'MESSAGES_QUEUE',
	NETWORK_DOWN: 'NETWORK_DOWN',
	NEXT_MESSAGE: 'NEXT_MESSAGE',
	ON_ADD_TIMEOUT: 'ON_ADD_TIMEOUT',
	ON_CLEAR_TIMEOUTS: 'ON_CLEAR_TIMEOUTS',
	ON_DONE_INTRO: 'ON_DONE_INTRO',
	ON_SEND: 'ON_SEND',
	ON_SEND_MSG_TO_HUMAN: 'ON_SEND_MSG_TO_HUMAN',
	PUSHER_MANAGER: 'PUSHER_MANAGER',
	REQUEST_FAILED: 'REQUEST_FAILED',
	RESPONSE: 'RESPONSE',
	RESPONSE_ID: 'RESPONSE_ID',
	RESTART: 'RESTART',
	RETRY_ERROR_MESSAGE: 'RETRY_ERROR_MESSAGE',
	RETRYING_ERROR_MESSAGE: 'RETRYING_ERROR_MESSAGE',
	RUN_MESSAGE: 'RUN_MESSAGE',
	SET_DIRTY_RESPONSE: 'SET_DIRTY_RESPONSE',
	START_APP: 'START_APP',
	START_APP_ERROR: 'START_APP_ERROR',
	START_OVER: 'START_OVER',
	STEPS: 'STEPS',
	SUBSCRIBE_CHAT: 'SUBSCRIBE_CHAT',
	TOGGLE_YELLOW_BOX: 'TOGGLE_YELLOW_BOX',
	TYPING_EMULATION: 'TYPING_EMULATION',
	UNSUBSCRIBE_CHAT: 'UNSUBSCRIBE_CHAT',
	UPDATE_BOT: 'UPDATE_BOT',
	UPDATE_ROOM_PROPS: 'UPDATE_ROOM_PROPS',
	YELLOW_BOX_ERROR: 'YELLOW_BOX_ERROR',
	VARIABLES: 'VARIABLES',
	REMOVE_FROM_CART: 'REMOVE_FROM_CART',
	ADD_TO_CART: 'ADD_TO_CART',
	UPDATE_EDIT_ORDER: 'UPDATE_EDIT_ORDER',
	ORDERS: 'ORDERS',
	HUMANTAKEOVER: 'HUMANTAKEOVER',
	GENERATE_QR_CODE_PAYMENT: 'GENERATE_QR_CODE_PAYMENT',
	SAVE_QR_CODE_PAYMENT: 'SAVE_QR_CODE_PAYMENT',
	CHECK_VERIFY_TRANSACTION: 'CHECK_VERIFY_TRANSACTION',
	STORE_USER: 'STORE_USER',
	ON_CHAT_ERROR: 'ON_CHAT_ERROR'
}

export default types
