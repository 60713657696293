import { put, take } from 'redux-saga/effects'

import actions from '../actions'
import types from '../types'

function* onDoneAppIntro() {
	while (true) {
		yield take(types.ON_DONE_INTRO)
		try {
			console.info('done app intro')
			yield put(actions.isShowingIntro(false))
			yield put(actions.runMessage('START'))
		} catch (err) {
			console.error('[onDoneAppIntro] ', err)
		}
	}
}

export default onDoneAppIntro
