import AudioRecorder from "audio-recorder-polyfill";
import React from "react";

class Recorder extends React.Component {
	start = () => {
		const { onStop, onStart } = this.props;
		if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
			this.setState({ error: "Audio recording not supported." });
			return;
		}

		navigator.mediaDevices.getUserMedia({ audio: true }).then((stream) => {
			this.recorder = new AudioRecorder(stream);

			this.recorder.addEventListener("dataavailable", (e) => {
				onStop(URL.createObjectURL(e.data));
			});

			this.recorder.start();
			onStart();
		});
	};

	stop = () => {
		this.recorder.stop();
		this.recorder.stream.getTracks().forEach((i) => i.stop());
	};

	componentDidUpdate = (prevProps) => {
		if (
			this.props.command &&
			this.props.command !== "none" &&
			prevProps.command !== this.props.command
		) {
			this[this.props.command]();
		}
	};

	componentWillUnmount = () => {
		if (this.props.onUnmount) this.props.onUnmount(this.stream);
	};

	render() {
		return false;
	}
}

export default Recorder;
