import ABTest from '../../../messages/abTest'
import Condition from '../../../messages/condition'
import Javascript from '../../../messages/javascript'
import JumpTo from '../../../messages/jumpTo'
import SMTP from '../../../messages/smtp'
import Webhook from '../../../messages/webhook'
import HumanTakeover from '../../../messages/humanTakeOver'
import ClearCart from '../../../messages/ecommerce/clearCart'
import BusinessHours from '../../../messages/businessHours'

const backends = {
	'AB-TEST': ABTest,
	CODE: Javascript,
	CONDITION: Condition,
	'JUMP-TO': JumpTo,
	SMTP: SMTP,
	WEBHOOK: Webhook,
	'HUMAN-TAKEOVER': HumanTakeover,
	'CLEAR-CART': ClearCart,
	"BUSINESS-HOURS": BusinessHours,
}

export default backends
