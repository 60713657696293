var unique = function (origArr) {
	var newArr = [],
		origLen = origArr.length,
		found,
		x,
		y

	for (x = 0; x < origLen; x++) {
		found = undefined
		for (y = 0; y < newArr.length; y++) {
			if (origArr[x] === newArr[y]) {
				found = true
				break
			}
		}
		if (!found) {
			newArr.push(origArr[x])
		}
	}
	return newArr
}

var uniqID = function () {
	var ts = +new Date()
	var tsStr = ts.toString()

	var arr = tsStr.split('')
	var rev = arr.reverse()

	var filtered = unique(rev)

	return filtered.join('')
}

export default uniqID
