import { Image, Text, View } from 'react-native'

import React from 'react'
import actions from '../../redux/actions'

import { connect } from 'react-redux'

class GenerateQrCodePayment extends React.Component {
	constructor(props) {
		super(props)
		this.state = {}
	}

	componentDidMount = () => {
		const { question, generateQrCodePayment, app, user } = this.props
		generateQrCodePayment(question.input, app._id, user)
	}

	render() {
		const { qr_code_url, question } = this.props
		return (
			<View >
				<Text style={styles.text}>{question.question}</Text>
				<Image source={{ uri: qr_code_url.qr_code_url }} style={{ width: 260, height: 260 }} />
			</View>
		)
	}
}

const mapStateToProps = (state) => ({
	answers: state.chat.answers,
	qr_code_url: state.chat.qrCodePaymentURL,
	app: state.chat.app,
	user: state.chat.user,
})

const mapDispatchToProps = (dispatch, ownProps) => ({
	storeAnswers: (answers, answer) => {
		dispatch(actions.answers(answers, answer))
	},
	onStartApp: () => {
		dispatch(actions.onStartApp())
	},
	generateQrCodePayment: (input, appID, userID) => {
		dispatch(actions.generateQrCodePayment(input, appID, userID))
	},
})

const Container = connect(mapStateToProps, mapDispatchToProps)(GenerateQrCodePayment)

export default Container

const styles = {
	image: {
		borderTopLeftRadius: 20,
		borderTopRightRaduis: 20,
		flex: 1,
		height: 120,
		width: '100%',
	},
	text: {
		backgroundColor: '#F8F8F8',
		borderColor: '#EEE',
		width: 260,
		height: 'auto',
		justifyContent: 'center',
		alignItems: 'center',
		overflowWrap: 'break-word',
		padding: '30px'
	},
}
