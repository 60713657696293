import * as apis from '../../redux/apis'
import _ from 'lodash'

const BusinessHours = async ({ question }) => {


	const data = await apis.checkBusinessHours(question.input)
	return _.get(question, `trigger.${data.business_status}`)
}

export default BusinessHours
