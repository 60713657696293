export const featureFlags = {
	chat: 'chat',
}

export const animeInterval = 0.2 * 1000

export const footerMaxHeight = 240

export const footerMinHeight = 60

export const grobal_vars_id = 'ms_00000'