import * as apis from '../apis'
import actions from '../actions'
import { call, put, take } from 'redux-saga/effects'
import types from '../types'

function* generateQrCodePayment() { // Use 'function*' to define a generator function
    while (true) {
        const {
            payload: { input, appID, userID },
        } = yield take(types.GENERATE_QR_CODE_PAYMENT)
        try {
            let userId = userID

            if (userID === "" || userID === undefined) {
                userId = appID
            }

            const response = yield call(
                apis.generateQrCodePayment,
                input,
                userId
            )
            yield put(actions.qrCodePayment(response))
        } catch (err) {
            console.error('[generateQrCodePayment] ', err)
        }
    }
}

export default generateQrCodePayment
