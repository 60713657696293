// source: http://doublespringlabs.blogspot.com.br/2012/11/decoding-polylines-from-google-maps.html
export default function decode(encoded) {
	// array that holds the points

	var points = []
	var index = 0,
		len = encoded.length
	var lat = 0,
		lng = 0
	while (index < len) {
		var b,
			shift = 0,
			result = 0
		do {
			b = encoded.charAt(index++).charCodeAt(0) - 63 //finds ascii                                                                                    //and substract it by 63
			result |= (b & 0x1f) << shift
			shift += 5
		} while (b >= 0x20)

		var dlat = (result & 1) !== 0 ? ~(result >> 1) : result >> 1
		lat += dlat
		shift = 0
		result = 0
		do {
			b = encoded.charAt(index++).charCodeAt(0) - 63
			result |= (b & 0x1f) << shift
			shift += 5
		} while (b >= 0x20)
		var dlng = (result & 1) !== 0 ? ~(result >> 1) : result >> 1
		lng += dlng

		points.push({
			latitude: lat / 1e5,
			longitude: lng / 1e5,
		})
	}
	return points
}
