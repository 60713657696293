// Redux
import { applyMiddleware, compose, legacy_createStore as createStore } from 'redux'
// Redux persist
import { persistReducer, persistStore } from 'redux-persist'

// Reducers
import { chat as chatReducer } from './reducers'
import { combineReducers } from 'redux'
// Redux Saga
import createSagaMiddleware from 'redux-saga'
// Saga
import rootSaga from './sagas'
// Storage
import storage from 'react-native-storage'

// create the saga middleware
const sagaMiddleware = createSagaMiddleware()

// Storage persist config
const persistConfig = {
	key: 'root',
	storage,
	blacklist: ['chat'],
}

const chatPersistConfig = {
	key: 'secret',
	storage: storage,
	blacklist: ['pusherManager', 'isRetryingErrorMessage', 'startAppError', 'isLoadingRequireData'],
	transforms: [],
}

const rootReducer = combineReducers({
	chat: persistReducer(chatPersistConfig, chatReducer),
})

const persistedReducer = persistReducer(persistConfig, rootReducer)

const composeWithDevTools =
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

// mount it on the Store
export const store = createStore(
	persistedReducer,
	composeWithDevTools(applyMiddleware(sagaMiddleware))
)

export const persistor = persistStore(store)

sagaMiddleware.run(rootSaga)

function handleChange() {
	const currentValue = store.getState()
	window.store = currentValue
}

handleChange()