import CryptoJS from "crypto-js";
import { decompress } from "@cloudpss/zstd";

// Function to handle received data
export const decompressedZstd = async (compressedData) => {
    const compressed = new Uint8Array(compressedData);

    // Parallel decompression for large data sets
    const decompressed = await decompress(compressed);

    console.log("decompressedZstd:", decompressed);
    // Convert back to string and process the message
    const message = new TextDecoder().decode(decompressed);
    console.log("Received decompressed message:", message);
    return message;
};

export const decryptAesPusher = (data, key) => {
    let result = CryptoJS.AES.decrypt(data, key).toString();
    result = CryptoJS.enc.Hex.parse(result).toString(CryptoJS.enc.Utf8);
    return JSON.parse(result);
};

const events = {};
const bindWithChunking = (channel, event, callback) => {
    async function handleChunkedEvent(data) {
        let { id, index, chunk, final } = data;

        if (!events.hasOwnProperty(id)) {
            events[id] = { chunks: [], receivedFinal: false };
        }

        let ev = events[id];
        ev.chunks[index] = chunk;

        if (final) {
            ev.receivedFinal = true;
        }

        if (
            ev.receivedFinal &&
            ev.chunks.length === Object.keys(ev.chunks).length
        ) {
            const data = JSON.parse(ev.chunks.join(""));
            console.log("receivedFinal :", data);
            const result = await decompressedZstd(data);
            callback(result);
            delete events[id];
        }
    }

    channel.bind(event, callback);
    channel.bind("chunked-" + event, handleChunkedEvent);
};

export default bindWithChunking;
