const WebBrowser = {
    openBrowserAsync: (url) => window.open(url, '_blank')
}

const Location = {

}

const Permissions = {

}

const Constants = {
    statusBarHeight: 0
}

const StackNavigation = {
    Header: {
        HEIGHT: 0
    }
}

export {
    WebBrowser,
    Location,
    Permissions,
    Constants,
    StackNavigation
}